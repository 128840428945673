@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {
    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;
    }
}


.Logo,
.Thanks,
.Name,
.Message,
.Team,
.CallToAction {
    width: 100%;
    text-align: center;
}

.Logo {
    margin-bottom: 20px;

    img {
        max-width: 300px;
        margin: 0 auto;

	@include xs {
	    max-width: 70%;
	}
    }
}

.Thanks,
.Name,
.CallToAction {
    p {
        margin-block-start: 0;
        margin-block-end: 0;
        text-transform: uppercase;
        letter-spacing: 2px;

	@include xs {
	    line-height: 32px;
	}

    }
}

.Thanks {
    margin-bottom: 30px;

    p {
        font-size: 32px;

	@include xs {
	    font-size: 24px;
	}

    }
}

.Name {
    margin-bottom: 100px;

    @include xs {
	margin-bottom: 50px;
    }


    p {
        font-size: 58px;
        font-weight: bold;
        letter-spacing: 4px;

	@include xs {
	    font-size: 38px;
	    line-height: 38px;
	}
    }
}

.Message,
.Team {
    margin-bottom: 80px;

    p {
        max-width: 450px;
        margin: 0 auto;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    a {
	color: #000;
	text-transform: uppercase;

	&:hover {
	    text-decoration: underline;
	}
    }
}

.Team {
    margin-bottom: 180px;

    @include xs {
    	margin-bottom: 90px;
    }

    p {
        max-width: 400px;
    }
}

.CallToAction {
    p {
        font-size: 34px;
        letter-spacing: 4px;

	@include xs {
	    font-size: 24px;
	    letter-spacing: 2px;
	}

    }
}
