.Circus2015 {
    background-image: url('../../../assets/images/circus/2015/nBkPoY1NIl.jpg' );
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    h1 {
	color: #fff;
	font-size: 3.5rem;
	text-align: center;
	font-weight: 600;
	line-height: 1.2em;
	margin-bottom: 1em;
	margin-top: 1em;
	max-width: 800px;
    }

    .Intro {
	padding: 50px;

	h3 {
	    color: #fff;
	    max-width: 800px;
	    text-align: center;
	    font-weight: 400;
	    font-size: 1.5em;
	    margin-top: 2rem;
	    line-height: 1.4em;
	    margin-bottom: 2rem;
	}

	p {
	    font-size: 16px;
	    line-height: 1.5em;
	    color: #fff;
	    max-width: 800px;
	    text-align: center;
	    font-weight: 400;
	}
    }

}

.Hero {
    background-size: cover;
    height: 50vh;
    padding-top: 65vh;
    background-position: center;
    background-image: url('../../../assets/images/circus/2015/coliseu_circus_2015.jpg');
    box-sizing: content-box !important;
    z-index: 0;

    img {
	position: absolute;
	top: 30vh;
	box-sizing: border-box;
	display: block;
	z-index: 1;
	max-width: 90%;
    }
}
