@import "../../mixins.scss";
@import "../../variables.scss";

.Slide {
    cursor: pointer;
}

.ActivityCarousel {
    margin-top: $header-menu-height;

    @include gt-xs {
	margin-top: $header-menus-height;
    }
}
