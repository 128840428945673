@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin-top: $header-height;

    @include gt-xs {
	margin-top: $header-menu-height;
    }
}
