@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin-top: $header-height + 25px;

    @include gt-xs {
	margin-top: $header-menu-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	.ValidationError {
	    padding-top: 25px;
	    color: rgb(221, 44, 0);
	    font-size: smaller;
	}

	.Success {
	    padding-top: 25px;
	    font-size: smaller;
	}

	.ReadPolicy {
	    margin-block-start: 0;
	    margin: 25px 0 ;
	    font-size: 18px;

	    a {
		color: inherit;
		text-decoration: none !important;

		&:hover {
		    text-decoration: underline;
		}
	    }

	}
    }
}
