@import "../../mixins.scss";

.EventPhoto {
    cursor: pointer;
    flex-basis: 40%;
    margin: 1px;
    height: 250px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include gt-xs {
	flex-basis: 33%;
	height: 300px;
    }
}

.GalleryTitle {
    text-align: center;
    text-transform: uppercase;
    font-size: 56px;
    line-height: 67px;
    color: #878787;
    font-weight: 300;
}
