@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {

    margin-top: $header-height;
    
    @include gt-xs {
	margin-top: $header-menu-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 80px 40px 60px;
	}

	width: 100%;
	max-width: $content-max-width;


	.Description {

	    line-height: 1.2;
	    margin-top: 20px;
	    
	    @include gt-xs {
		margin-top: 50px;
	    }	
}

	.Action {

	    @include xs {
		display: block;
	    }
	    
	    margin: 50px 0;
	}

    }
}
