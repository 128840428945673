@import "../../variables.scss";
@import "../../mixins.scss";

.ArchiveYears {

    top: $header-submenu-height;

    @include gt-xs {
	top: $header-menus-height;
    }

    position: fixed;
    background-color: #ffffff;
    min-height: $subsubmenu-height;
    border-bottom: 1px solid #000000;
    z-index: 10000;
    // scrollable horizontal menu
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;


    .YearButton {
	margin: 0 10px;
	color: #222222;
	opacity: .7;
	cursor: pointer;

	&:hover {
	    text-decoration: underline;
	}
    }

    .Selected {
	color: rgba(0,0,0,1);
	font-weight: bold;
	text-decoration: underline;
	font-weight: bold;
	opacity: 1;
    }
}
