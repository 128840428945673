@import "../../mixins.scss";

.Wrapper {
    margin-top: 50px;

    > div {
	cursor: pointer;
	text-align: center;
	margin-bottom: 20px;

	img {
	    margin-bottom: 10px;
	    width: 100%;
	    @include image-opacity-transition;
	} 
   }
}
