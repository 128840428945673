.Wrapper {

    .Image {
	background-image: url("../../assets/images/newsletter/popup.webp");
	background-size: cover;
	aspect-ratio: 2;
    }

    .Content {
	margin: 20px;
	text-align: center;

	button {
	    margin-top: 20px;
	}
    }
}
