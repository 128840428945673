@import "../../mixins.scss";

.ActivitySearch {

    margin-top: 50px;

    @include gt-xs {
	margin-top: 100px;
    }

    div {
	border: 1px solid black;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 14px;
	text-align: center;
	padding: 10px;
	cursor: pointer;
    }

    .Selected {
	background-color: #000;
	color: #fff;
    }
}


.ActivityList {
    margin-top: 40px;
}
