.ActionButton {
    cursor: pointer;
    border: 1px solid #000000;
    padding: 5px 0;
    text-align: center;
    display: inline-block;

    .Name {
	text-transform: uppercase;
	padding: 20px 20px;
    }

    &:hover {
	border: 1px solid #808080;
	text-decoration: underline;
    }

}

.FullWidth {
    display: block;
}

.Expanded {
    background-color: #ffffff;
}

.More {
    margin-top: 10px;
}
