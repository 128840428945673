@import "../../mixins.scss";
@import "../../variables.scss";

$padding-left: 40px;

@mixin header-style {
    text-align: center;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    padding: 5px 0;

    @include gt-xs {
	text-align: left;
	padding-left: $padding-left;
    }
}

@mixin text-style {
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;

    @include gt-xs {
	text-align: left;
	padding-left: $padding-left;
    }
}

.SectionBanner {
    margin-top: $header-menu-height;
    background-size: cover;
    background-image: url("../../assets/images/educational/cta/cta_hero_mobile.webp");
    aspect-ratio: 415/277;

    @include gt-xs {
	margin-top: $header-menus-height;
	background-size: cover;
	background-image: url("../../assets/images/educational/cta/cta_hero_desktop.webp");
	aspect-ratio: 3;
    }
}

.Wrapper {
    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;


	.Dates {

	    padding: 25px 0 25px 0;

	    @include gt-xs {
		padding: 0 0 25px 0;
	    }

	    p {
		@include text-style;
	    }

	}

	.TargetAudience {

	    h3 {
		@include header-style;
	    }

	    p {
		@include text-style;
		padding: 20px 0;
	    }
	}


	.AdditionalInfo  {

	    h3 {
		@include header-style;
	    }

	    p {
		@include text-style;
	    }

	    .AdditionalInfoContent {
		padding: 20px 0;
	    }
	}

	.Title {
	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;


		@include gt-xs {
		    text-align: left;
		    margin-bottom: 5px;
		}
	    }
	}

	.Introduction {

	    margin: 0 0 25px 0;
	    
	    @include gt-xs {
		margin: 25px 0;
	    }

	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;

		@include gt-xs {
		    text-align: left;
		}
	    }
	}
	
	.Description {
	    margin: 25px 0 50px 0;

	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;

		@include gt-xs {
		    text-align: left;
		}
	    }
	}

	.Banner {

	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;

		@include gt-xs {
		    text-align: left;
		}

	    }

	    .Photo {
		background-size: cover;
		background-image: url("../../assets/images/educational/cta/cta_banner_mobile.webp");
		aspect-ratio: 341/166;

		@include gt-xs {
		    background-size: cover;
		    background-image: url("../../assets/images/educational/cta/cta_banner_mobile.webp");
		    aspect-ratio: 721/346;
		}
	    }
	}

	.Logos {
	    
	    @include gt-xs {
		margin: 50px 0 25px 0;
	    }

	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;
	    }

	    > div {
		text-align: center;

		img {
		    max-width: 60%;
		    
		    @include gt-xs {
			max-width: 220px;
		    }
		}
	    }
	}
    }
}
