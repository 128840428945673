@import "../../mixins.scss";
@import "../../variables.scss";

.EducationalHighlights {
    margin-top: 50px;
    
    .Name {
	text-transform: uppercase;
	 margin-bottom: 10px;

	@include gt-xs {
	    margin-bottom: 40px;
	}
    }

    
    .Highlights {

	@include xs {
	    margin-top: 25px;
	}

	.SectionHighlight {
	    cursor: pointer;

	    &:first-of-type {

		@include gt-xs {
		    margin-right: 50px;
		}
	    }
	    
	    img {
		max-width: 100%;

		@include gt-xs {
		    max-width: 450px;
		}
		
		@include image-opacity-transition;
	    }

	    .Link {
		margin-top: 10px;
		text-transform: uppercase;
		@include Truncate();
		text-align: center;
		max-width: 450px;
	    }
	}
    }

}
