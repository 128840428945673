@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    background-color: #000000;
    height: $header-height;
    position: fixed;
    top: 0;
    z-index: 1299;
    padding: 0 $padding-mobile;

    @include gt-xs {
	padding: 0 $padding-desktop;
    }

    section {
	max-width: $content-max-width;
	color: #ffffff;
	max-height: 100%;

	a {
	    display: flex;
	    align-items: center;
	    height: 100%;

	    img {
		max-height: 50%;
	    }

	}
    }
}
