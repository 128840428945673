@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {
    margin-top: $header-menu-height;

    @include gt-xs {
	margin: 0 auto;
	margin-top: $header-menus-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;
    }
}
