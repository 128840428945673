@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;
    }

    .NameWrapper {
    	margin-bottom: 30px;
    }

    .DetailsWrapper {
    	margin-bottom: 25px;
    }

    .DescriptionWrapper {
    	margin-bottom: 50px;
    }

    .ActionWrapper {
    	margin-bottom: 125px;
    }
}

.BackToSchool {

    @include xs {
	margin-left: 20px;
    }
}
