@import "../../../mixins.scss";
@import "../../../variables.scss";

$circus-text: #416DA7;

@mixin roc-grotesk-300 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 300;
}

@mixin roc-grotesk-400 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
}

@mixin roc-grotesk-500 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
}

@mixin roc-grotesk-700 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 700;
}

@mixin roc-grotesk-wide-700 {
    font-family: "roc-grotesk-wide", sans-serif;
    font-weight: 700;
}



.Hero { 
    background-size: cover;
    background-image: url('../../../assets/images/circus/2024/hero_mobile.webp');
    aspect-ratio: 1.5;
    margin-top: $header-menu-height;

    @include gt-xs {
	background-image: url('../../../assets/images/circus/2024/hero_desktop.webp');
	aspect-ratio: 3.0;
	margin-top: $header-menus-height;
    }
}

.Separator {
    text-align: center;
    padding: 10px 0;
    position: relative;
    margin-bottom: 50px;
}

.Line {
    height: 2px;
    background-color: $circus-text;
    width: 100%;
    margin: 15px 0;
}

.Star {
    @include roc-grotesk-wide-700;
    font-size: 20px;
    margin-bottom: 7px;

    @include gt-xs { 
	margin-bottom: 0;
	font-size: 50px;
    }
}


.SectionTitle {
    margin-top: 50p;
    margin-bottom: 50px;

    img {
	width: 100%;
    }
}

.Wrapper {
    background-color: #FCF3E2;
    
    .Circus2024 {
	color: $circus-text;
	max-width: $content-max-width;

	padding: 0 $padding-mobile;

	@include gt-xs {
	    margin-top: 100px;
	    margin-bottom: 100px;
	    padding: 0 $padding-desktop;
	}

	p, h5 {
	    margin-block-start: 0;
     	    margin-block-end: 0;
	}

	h2 {
	    text-align: center;
	    @include roc-grotesk-wide-700;
	    text-transform: uppercase;
	    font-size: 40px;
	    margin-top: 50px;
	    margin-top: 50px;
	}

	.Intro {
	    
	    p {
		@include roc-grotesk-400;
		font-size: 30.83px;
		line-height: 44.39px;
		margin-bottom: 25px;
		text-align: center;
	    }

	    .Credits {

		margin-top: 50px;

		h5 {
		    @include roc-grotesk-500;
		    text-align: center;
		    font-size: 25.15px;
		    line-height: 36.22px;
		}

		p {
		    @include roc-grotesk-500;
		    text-align: center;
		    text-transform: uppercase;
		    margin-bottom: 10px;
		    font-size: 38.6px;
		    line-height: 55.58px;
		}

		.Actors {
		    margin-top: 50px;
		}
	    }
	}


	.Sessions {
	    
	    margin-top: 100px;

	    .SessionMonth {
		margin-bottom: 25px;
	    }

	    .SessionGrid {
		margin-bottom: 25px;
	    }

	    .SignLanguage {
		margin-bottom: 25px;

		p:first-of-type {
		    font-weight: 600;
		}
	    }
	    
	    a {
		text-decoration: none;
		color: $circus-text;
	    }

	    .Session {
		border: 2px solid $circus-text;
		border-radius: 20px;
		padding: 5px;

		@include gt-xs {
		    border: 3px solid $circus-text;
                    padding: 20px;
		}

		p {
                    margin-block-start: 0;
                    margin-block-end: 0;
		}
            }

            .Call2Action {
		margin-top: 50px;
		margin-bottom: 50px;

		button {
                    border: 2px solid $circus-text;
                    color: $circus-text;

                    @include gt-xs {
			padding: 10px 100px;
                    }

		    span {
			@include roc-grotesk-500;

			line-height: 35.51px;

			@include gt-xs {
			    font-size: 24.66px;
			}
		    }
		}
	    }


	    p {
		text-align: center;
	    }

	    .Discounts {
		p {
		    margin-bottom: 0;
		}
	    }

	}

	.Artists {

	    margin-top: 100px;

	    .ArtistsContainer {

		@include gt-xs {
		    margin-left: 100px;
		}
		
		.Artist {
		    margin-bottom: 15px;

		    
		    img {
			max-width: 85%;

			@include gt-xs {
			    margin-right: 30px;
			}
		    }

		    .ArtistWrapper {
			padding-top: 15px;
		    }
		}
	    }
	}

	.Staff {
	    margin-top: 100px;

	    h2 {
		text-align: center;
	    }


	    p {

		text-align: center;
		@include roc-grotesk-400;
		font-size: 20.14px;
		line-height: 29px;

		b {
		    text-transform: uppercase;
		    @include roc-grotesk-700;
		    font-size: 18.55px;
		    line-height: 26.72px;
		}

	    }

	    .TechTeam {
		margin-top: 50px;
	    }

	    .Performers {
		margin-top: 50px;
	    }
	    
	    .Coordination {
		margin-top: 50px;
		margin-bottom: 25px;
	    }
	}

	.Footer {
	    margin-top: 25px;

	    img {
		width: 95%;
	    }

	    @include gt-xs {
		margin-top: 100px;

		img {
		    width: 70%;
		}
	    }
	}
    }

}

.Disabled {
    border: 3px solid #808080 !important;
    border-radius: 20px;
    padding: 20px;

    p {
	margin-block-start: 0;
	margin-block-end: 0;
	color: #808080 !important;
    }
}
