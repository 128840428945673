@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {
    margin-top: $submenu-height;

    .ScheduleView {
	max-width: $content-max-width;
	padding: 0 30px;
	box-sizing: border-box;
    }
}
