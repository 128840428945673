@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;
    }

    .Title {
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 75px;

	@include xs {
	    font-size: 20px;
	    margin-bottom: 50px;
	}

    }

    .Date {
	text-align: center;
	font-size: 68px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 4px;

	@include xs {
	    font-size: 36px;
	    letter-spacing: 2px;
	}
    }

    .Message {
	width: 100%;
	text-align: center;
	margin-bottom: 75px;

	@include xs {
	    margin-bottom: 50px;
	}

	p {
	    font-size: 28px;
	    max-width: 450px;
	    margin: 10px auto;
	    text-align: center;
	    line-height: 32px;

	    @include xs {
		font-size: 20px;
		line-height: 24px;
	    }

	}
    }

}
