.PhotoGallery {
    background-color: rgba(0, 80, 100, 0.5);
    color: #fafafa;

    h1 {
	color: #fff !important;
	font-size: 3.5rem !important;
	text-align: center !important;
	font-weight: 600 !important;
	line-height: 1.2em !important;
	margin-bottom: 1em !important;
	margin-top: 1em !important;
	max-width: 800px !important;
    }
}
