@import "../../mixins.scss";
@import "../../variables.scss";

.SectionBanner {
    margin-top: $header-menu-height;
    background-size: cover;
    background-image: url("../../assets/images/institution/friends/friends_hero_mobile.webp");
    aspect-ratio: 415/277;

    @include gt-xs {
	margin-top: $header-menus-height;
	background-size: cover;
	background-image: url("../../assets/images/institution/friends/friends_hero_desktop.webp");
	aspect-ratio: 3;
    }
}

.Wrapper {
    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	.Action {
	    margin-top: 10px;

	    @include gt-xs {
		margin-top: 25px;
	    }
	}
	
    }
}
