@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin-top: $header-menu-height;

    @include gt-xs {
	margin-top: $header-menus-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	section {

	    h6 {
		margin-bottom: 20px;
	    }

	    .TextImage {
		margin: 40px 0;
		overflow: auto;
	    }

	    .PageImage {
		margin: 0 auto 20px 0;
		max-width: calc(100% - 40px);

		@include gt-xs {

		    float: right;
		    margin-left: 40px;
		    margin-bottom: 20px;
		    max-width: 33%;
		}
	    }
	}
    }
}
