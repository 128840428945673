@import "../../mixins.scss";

.Wrapper {

    .Image {
	opacity: 0.85;
	@include Transition300;

	img {
	    width: 100%;
	    aspect-ratio: 5/4;

	    object-fit: cover;
	}

	&:hover {
	    opacity: 1;
	}

	a {
	    display: block;
	    height: 100%;
	}
    }

    .Name {
	text-align: left;
	font-weight: 700;
	padding-top: 5px;

	a {
	    text-decoration: none;
	    color: inherit;

	    &:hover {
		text-decoration: underline;
	    }
	}
    }

}
