@import "../../mixins.scss";
@import "../../variables.scss";

.Empty {
    padding: 50px 0;
}

.Wrapper {
    margin-top: $header-height;

    @include gt-xs {
	margin-top: $header-menu-height;
    }

    main {
	padding: 20px 20px;

	@include gt-xs {
	    padding: 40px 0;
	}

	width: 100%;
	max-width: $content-max-width;


	.Information {
	    order: 2;
	    @include gt-xs {
		order: 1;
		padding-right: 50px;
	    }
	}

	.Poster {
	    order: 1;
	    @include gt-xs {
		order: 2
	    }
	}

	.EventGalleryWrapper {
	    order: 3;
	    @include gt-xs {
		margin-top: 50px;
	    }
 	}

	.EventName {
	    font-size: 28px;
	    line-height: 33px;
	    color: #212121;
	    margin-block-start: 0;
	    margin-bottom: 0;

	    @include gt-xs {
		font-size: 56px;
		line-height: 67px;
	    }
	}

	.EventDate {
	    font-size: 24px;
	    margin: 0;
	    margin-top: 10px;
	    line-height: 19px;

	}

	.EventTime {
	    font-size: 24px;
	    line-height: 19px;
	    margin: 10px 0 20px 0;

	    @include gt-xs {
		margin: 0;
		margin-top: 10px;
	    }
	}

	.RoomName {
	    color: #666666;
	    margin-block-start: 25px;
	    margin-block-end: 0px;
	}

	.GenericInfo {

	    margin-block-start: 0;
	    margin-block-end: 0;

	    span:first-of-type {
		text-transform: uppercase;
		font-size: 11px;
		line-height: 1.5em;
		font-weight: 700;
		padding-top: .5em;
		padding-right: .5em;
		text-transform: uppercase;
		color: #212121;
		opacity: .8;
	    }

	    span:nth-of-type(2), a {
		font-size: 16px;
		color: #666666;
		margin-left: 5px;
		text-decoration: none;
	    }
	}

	.Tickets {
	    margin-bottom: 50px;

	    .BreakOnNew {
		white-space: pre-line;
	    }
	}

	.Description {
	    p {
		font-family: "roc-grotesk", sans-serif;
		font-size: 22px;
		font-weight: 400;
		line-height: 34px;
		margin-bottom: 30px;

		@include gt-xs {
		    font-size: 24px;
		}
	    }
	}

	.Call2Action {
	    margin-top: 50px;
	}

	.RoomSheet {
	    margin-top: 25px;
	    padding: 7px 0;
	    cursor: pointer;
	    border-top: 1px solid #666666;
	    border-bottom: 1px solid #666666;
	    color: #666666;

	    span {
		padding-left: 10px;
	    }
	}

	.VideoPlayerWrapper {
	    margin: 50px 0;
	}
    }
}
