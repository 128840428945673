@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {
    margin-top: 40px;
    padding: 0 5px;

    .SectionName {
	font-weight: 400;
	font-size: 45px;
	line-height: 1.05;
	text-align: center;
    }

    .Carousel {
	width: calc(100% - 100px);
    }

    .Arrow {
	width: 50px;
	cursor: pointer;

	svg {
	    font-size: 35px;
	}

	.Disabled {
	    color: #808080;
	    cursor: default;
	}
    }

    .OutlineButtonWrapper {
	margin-top: 20px;
	width: calc(100% - 100px);
    }
}
