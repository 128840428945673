@import '../../mixins.scss';

.Highlights {
    width: 100%;
    position: relative;
    overflow: hidden;

    & > div:nth-of-type(2) {
    	animation-delay: .35s;
    }

    & > div:nth-of-type(3) {
    	animation-delay: .7s;
    }

    animation: fadein 300ms ease-in forwards;


    &:hover .Arrow {
	visibility: visible;
	opacity: 0.5;
    }

    .Arrow {
	position: absolute;
	top: 50%;
	color: white;
	font-size: 12px;
	height: 36px;
	width: 36px;
	z-index: 9999;
	border: 2px solid white;
	font-weight: 400;
	opacity: 0;
	visibility: hidden;
	@include Transition300();

	&:hover {
	    opacity: 1;
	}
    }

    .ArrowLeft {
	left: 20px;
    }

    .ArrowRight {
	right: 20px;
    }

}

.Highlight {
    position: relative;
    aspect-ratio: 4/4;
    flex-grow: 1;
    flex-shrink: 1;
    background-size: cover;
    background-position: center center;
    opacity: 0;
    overflow: hidden;
    transition: .5s;
    animation: slide-left 1s ease-out forwards;

    &::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	transition: transform 0.2s ease-in-out;
	transform: scale(1);
	background-image: inherit;
	background-repeat: inherit;
	background-size: cover;
	background-position: 50%;
    }

    &:hover::before {
	transform: scale(1.2);
    }

    .DateTime {
	padding: 0.16em 0 0.16em 0;

    }

    .EventLink {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-decoration: none;
    }

    .HighlightDetails {
	height: 100%;

	.Overlay {
	    position: absolute;
	    top: 30%;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background: linear-gradient(to top, black, transparent);
	    height: 70%;
        }

        .NewsDetails {
	    z-index: 1000;
	    opacity: 0;
	    animation: slide-right 1s ease-out forwards;
	    animation-delay: 1s;
	    height: 70%;

	    .Name {
		color: #fff;
		font-size: 42px;
		line-height: 1;
		font-weight: 400;
		margin: 0 1rem;
		text-align: center;
	    }

	    .DateTime {
		color: #fff;
		margin-bottom: 1rem;
		text-align: center;
		text-transform: uppercase;
	    }
	}
    }
}

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
