@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {

    @include gt-xs {
	max-width: $content-max-width;
	margin: 0 auto;
	margin-top: $header-menus-height + 50px;
	padding: 0 $padding-desktop;
    }

    .Image {
	@include xs {
	    margin-top: $header-menu-height;
	    order: 1;
	    width: 100%;
	    background-size: cover;
	    background-image: url("../../assets/images/policies/cookies/cookies_mobile.webp");
	    aspect-ratio: 1.33;
	}

	@include gt-xs {
	    order: 2;
	    background-image: url("../../assets/images/policies/cookies/cookies_desktop.webp");
	    background-repeat: no-repeat;
	    background-size: contain;
	}
    }

    .Text {

	@include xs {
	    order: 2;

	    main {
		max-width: $content-max-width;
		padding: $padding-mobile;
	    }
	}

	@include gt-xs {
	    order: 1;

	    main {
		padding: 0 40px 40px 0;
	    }
	}

	section {
	    margin: 40px 0;

	    a, a:visited, a:hover {
		color: $link-color;
		text-decoration: none;

		&:hover {
		    text-decoration: underline;
		}
	    }
	}
    }
}
