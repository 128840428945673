@import "../../mixins.scss";
@import "../../variables.scss";

.GoBackToSchool {
    margin-top: $header-height;
    background-color: #ececec;

    @include gt-xs {

	margin-top: $header-menu-height;
    }


    .Content {
        margin: auto;
        padding: 30px 0;
        max-width: 1200px;

        @include xs {
            padding: 15px 0;
        }

        a {
            text-decoration: none;
            color: #000000;
            font-size: 22px;

            @include xs {
                font-size: 12px;
            }
        }

        svg {
            @include xs {
                font-size: 14px;
            }
        }

        .GoBackButton {
            border: 1px solid #000000;
            max-width: 400px;
            padding: 5px;

            @include xs {
                margin-left: 20px;
                max-width: 50%;
            }

            span {
                margin-left: 20px;

                @include xs {
                    margin-left: 5px;
                }
            }
        }
    }
}
