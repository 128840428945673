@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin-top: $header-menu-height;

    @include gt-xs {
	margin: 0 auto;
	margin-top: $header-menus-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	.PostImage {
	    margin: 0 auto 20px 0;
	    max-width: calc(100% - 40px);

	    @include gt-xs {
		margin: 0;
		float: right;
		margin-left: 20px;
		top: 0;
		position: relative;
		max-width: 33%;
	    }
	}

	.Description {

	    p {
		font-family: "roc-grotesk", sans-serif;
		font-size: 22px;
		font-weight: 400;
		line-height: 34px;
		margin-bottom: 30px;

		@include gt-xs {
		    font-size: 24px;
		}
	    }
	}

	.VideoPlayerWrapper {
	    margin: 50px 0;
	}
    }
}
