@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    background-color: #000000;
    padding: 50px 0;

    section {
	max-width: $content-max-width;

    }
}

.Item {

    padding: 0 10px 20px 10px;
    color: #ffffff;
    box-sizing: border-box;

    @include xs {
	width: calc(100% / 2);
    }

    @include gt-xs {
	width: calc(100% / 4);
    }

    @include gt-md {
	width: calc(100% / 7);
    }

    text-align: center;

    h4 {
	font-weight: bold;
	font-size: 18px;
    }

    ul {
	margin: 0;
	padding: 0;

	li {
	    width: 100%;
	    list-style-type: none;
	    line-height: 32px;

	}
    }

    a {
	text-decoration: none;
	color: inherit;
	font-size: inherit;

	&:hover {
	    text-decoration: underline;
	}
    }

}
