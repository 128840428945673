@import "../../mixins.scss";
@import "../../variables.scss";

.SectionBanner {
    margin-top: $header-menu-height;
    background-size: cover;
    background-image: url("../../assets/images/educational/main/section_banner_mobile.webp");
    aspect-ratio: 3/2;

    @include gt-xs {
	margin-top: $header-menus-height;
	background-size: cover;
	background-image: url("../../assets/images/educational/main/section_banner_desktop.webp");
	aspect-ratio: 3;
    }
}

.Wrapper {
    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	.Introduction {
	    margin-top: 30px;
	}
    }
}

.Newsletter {

    margin: 50px 0;

    @include gt-xs {
	margin: 75px 0;
    }
}
