.SelectNewsletters {
    margin-top: 50px;
    margin-bottom: 25px;

    p {
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
    }

    li {
	list-style-type: none;
    }
}
