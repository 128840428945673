@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin-top: $header-height;
    padding: 75px 20px 50px 20px;

    @include gt-xs {
	max-width: $content-max-width;
	margin: 0 auto;
 	margin-top: $header-menu-height;
     }

    .Activate {

	.Logo {
	    text-align: center;

	    img {
		max-width: 60%;

		@include gt-xs {
		    max-width: 40%;
		}
	    }
	}

	h2 {
	    margin-top: 100px;
	    text-align: center;

	    @include xs {
		margin-top: 50px;
		font-size: 34px;
		margin-bottom: 20px;
	    }
	}

	h3 {
	    text-transform: uppercase;
	    text-align: center;
	}


	h6 {
	    margin-top: 100px;

	    text-align: center;
	    font-weight: 400;

	    @include xs {
		margin-top: 50px;
		font-size: 18px;
	    }
	}

	.Error {
	    color: #ff0000;
	}

    }
}
