@import "../../variables.scss";

.Wrapper {

    section {
	max-width: $content-max-width;

	.Banner {
	    margin: 50px 0;
	    background-color: #999999;
	    height: 140px;
	    width: 48%;
	}
    }


}
