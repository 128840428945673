@import "../../mixins.scss";
@import "../../variables.scss";

.HomeEventPreview {
    border: 10px solid $background;

    .First {
	margin-bottom: 5px;
    }

    .Cased {
	border: 1px solid #808080;
	margin-left: 10px;
	padding: 0 6px;
    }

    .Image {
	opacity: 0.85;
	@include Transition300;

	img {
	    width: 100%;
	    aspect-ratio: 5/4;

	    object-fit: cover;
	}

	&:hover {
	    opacity: 1;
	}

	a {
	    display: block;
	    height: 100%;
	}
    }

    .Name {
	font-size: 42px;
	text-align: center;
	@include Truncate;

	a {
	    text-decoration: none;
	    color: inherit;

	    &:hover {
		text-decoration: underline;
	    }
	}
    }

    .DayOfWeek, .Time {
	text-transform: uppercase;
	font-weight: 600;
    }

    .Last {
	padding: 5px 0;
	border-top: 1px solid #000000;
	border-bottom: 1px solid #000000;

	.RoomName {
	    @include xs {
		max-width: 40%;
		text-align: right;
		@include Truncate
	    }
	}
    }
}
