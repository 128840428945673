@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {

    margin-top: $header-menu-height - 75px;

    @include gt-xs {
	margin-top: $header-menus-height - 75px;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;
    }
}


.Team {

    order: 2;

    p {
	margin-block-start: 0;
	margin-block-end: 0;
	font-size: 16px;
	text-align: center;
    }

    .Section {
	margin-bottom: 25px;
    }

    @include gt-xs {
	order: 1;

	p {
	    font-size: 20px;
	    text-align: left;
	}

	.Section {
	    margin-bottom: 25px;
	}

    }
}

.Banner {

    order: 1;
    text-align: center;

    img {
	width: 80%;
	margin-bottom: 25px;
    }

    @include gt-xs {

	img {
	    width: 100%;
	}


	order: 2;
    }

}
