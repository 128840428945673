@import "../../mixins.scss";
@import "../../variables.scss";

$padding-left: 40px;

@mixin header-style {
    text-align: center;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    padding: 5px 0;

    @include gt-xs {
	text-align: left;
	padding-left: $padding-left;
    }
}

@mixin text-style {
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;

    @include gt-xs {
	text-align: left;
	padding-left: $padding-left;
    }
}


.SectionBanner {
    margin-top: $header-menu-height;
    background-size: cover;
    background-image: url("../../assets/images/educational/painting/painting_studio_hero_mobile.webp");
    aspect-ratio: 415/277;

    @include gt-xs {
	margin-top: $header-menus-height;
	background-size: cover;
	background-image: url("../../assets/images/educational/painting/painting_studio_hero_desktop.webp");
	aspect-ratio: 3;
    }
}

.Wrapper {
    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;


	.Dates {

	    padding: 25px 0 25px 0;

	    @include gt-xs {
		padding: 0 0 25px 0;
	    }

	    p {
		@include text-style;
	    }

	}

	.TargetAudience {

	    h3 {
		@include header-style;
	    }

	    p {
		@include text-style;
		padding: 20px 0;
	    }
	}


	.AdditionalInfo  {

	    h3 {
		@include header-style;
	    }

	    p {
		@include text-style;
	    }

	    .AdditionalInfoContent {
		padding: 20px 0;
	    }
	}

	.Prices {

	    margin-bottom: 20px;

	    h3 {
		@include header-style;
	    }

	    p {
		@include text-style;
	    }

	    .PricesContent {
		padding: 20px 0;
	    }

	}

	.Registrations {

	    h3 {
		@include header-style;
	    }

	    p {
		@include text-style;
	    }

	    .RegistrationsContent {
		padding: 20px 0;
	    }

	    a {
		color: #000000;
	    }

	}


	.Title {
	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;


		@include gt-xs {
		    text-align: left;
		    margin-bottom: 5px;
		}
	    }
	}

	.Description {

	    margin-bottom: 25px;

	    @include gt-xs {
		margin-top: 25px;
		margin-bottom: 0;
	    }

	    h3 {
		@include header-style;
	    }

	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;

		@include gt-xs {
		    text-align: left;
		}
	    }

	    .ButtonWrapper {

		margin: 10px 0;

		@include gt-xs {
		    margin: 20px 0;
		}
	    }

	    .BulletList {
		p {
		    margin: 10px 0;

		    &:first-of-type {
			margin-top: 20px;
		    }
		}
	    }
	}

	.CallToAction1 {

	    margin: 25px 0;

	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;
		line-height: 1.4em;

		@include gt-xs {
		    text-align: left;
		}

	    }
	}

	.Teacher {

	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;

		@include gt-xs {
		    text-align: left;
		}

	    }

	    .Photo {
		background-size: cover;
		background-image: url("../../assets/images/educational/painting/painting_studio_banner_mobile.webp");
		aspect-ratio: 341/166;

		@include gt-xs {
		    background-size: cover;
		    background-image: url("../../assets/images/educational/painting/painting_studio_banner_mobile.webp");
		    aspect-ratio: 721/346;
		}
	    }

	    .ButtonWrapper {
		margin: 20px 0;
	    }

	}

	.Logos {

	    @include gt-xs {
		margin: 50px 0 25px 0;
	    }

	    p {
		text-align: center;
		margin-block-start: 0;
		margin-block-end: 0;
	    }
	}


	.Materials {

	    h3 {
		@include header-style;
	    }

	    p {
		@include text-style;
	    }

	    .ButtonWrapper {
		margin: 20px 0;
	    }
	}

	.ExpandableButton {
	    cursor: pointer;
	    border: 1px solid #000000;
	    padding: 5px 0;
	}

	.Expanded {
	    background-color: #ffffff;
	}
    }

}

.FrequentQuestionsWrapper {



    .FrequentQuestions {



	@include gt-xs {
	    cursor: pointer;
	    width: 100%;
	    max-width: $content-max-width;
	    padding: 0 40px 20px 40px;
	}

	p {
	    text-align: center;
	    margin-block-start: 0;
	    margin-block-end: 0;
	}

	.Query {
	    text-align: center;
	    background-color: #ffffff;
	    border-top: 1px solid #000000;
	    padding: 10px 20px;
	}

	.Answer {
	    text-align: center;
	    padding: 5px 20px;
	}

	border-bottom: 1px solid #000000;
    }
}
