// antigas mixins

@mixin Transition300() {
    transition: all 300ms ease !important;
}

@mixin Transition1000() {
    transition: all 1000ms ease !important;
}

@mixin TextShadow() {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

@mixin Truncate() {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// alias para antigas mixins, para manter consistência

@mixin transition-300 {
    @include Transition300();
}

@mixin transition-1000 {
    @include Transition1000();
}

@mixin text-shadow {
    @include TextShadow();
}

@mixin truncate-text {
    @include Truncate();
}

// novas mixins

@mixin gt-xs {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin gt-sm {
    @media (min-width: 960px) {
        @content;
    }
}

@mixin gt-md {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin gt-lg {
    @media (min-width: 1920px) {
        @content;
    }
}

@mixin xs {
    @media (max-width: 599px) {
	@content;
    }
}

@mixin font-feature-settings {
    font-feature-settings: 'calt' 0, 'onum' 1, 'ss03' 1, 'kern' 1;
}

@mixin image-opacity-transition {
    opacity: 0.85;
    @include Transition300;

    &:hover {
        opacity: 1;
    }
}
