@import "../../variables.scss";
@import "../../mixins.scss";


$padding-left: 40px;

@mixin header-style {
    text-align: center;
    padding: 5px 0;

    @include gt-xs {
	text-align: left;
	padding-left: $padding-left;
    }
}

@mixin text-style {
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;

    @include gt-xs {
	text-align: left;
	padding-left: $padding-left;
    }
}

.Wrapper {

    margin-top: $header-height;

    @include gt-xs {
	margin-top: $header-menu-height;
    }
    
    main {
	padding: 50px 20px 40px 20px;

	@include gt-xs {
	    padding: 100px;
	}

	width: 100%;
	max-width: $content-max-width;

	.Description {

	    @include xs {
		margin-top: 40px;
	    }
	}
	
	.BackButton {

	    margin-top: 75px;
	    margin-bottom: 50px;
	    
	    @include xs {
		margin-top: 40px;
		margin-bottom: 45px;

		display: block;
	    }
	}
    }
}
