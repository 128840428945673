@import "../../mixins.scss";
@import "../../variables.scss";

.Activity {

    margin-bottom: 50px;

    #FirstRow {
	margin-bottom: 10px;

	.Title, .Subtitle {
	    text-transform: uppercase;
	    margin-block-start: 0;
	    margin-block-end: 0;

	    @include xs {
		padding: 0 20px;
	    }
	}

    }

    .Classes {

        @include xs {
            padding: 20px 20px 0px 20px;
        }

        @include gt-xs {
            padding-left: 100px;
        }
    }

    .Banner {
	img {
	    width: 100%;
	}
    }

    .Description {
	line-height: 1.3;

	@include xs {
	    padding: 20px;
	}

	@include gt-xs {
	    padding-left: 100px;
	}
    }
}
