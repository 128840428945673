@import "./variables.scss";

body {
    background-color: $background;
}

iframe {
    background-color: white;
}

@font-face {
  font-family: 'Colisseum';
  src: url('./assets/fonts/colisseum.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
