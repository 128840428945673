.TicketIcon {
    color: #fff;
}

.BigFormat {
    font-size: 40px !important;
}

.BuyButton {
    border: 4px solid #666;
    border-radius: 30px;
    padding: 5px 15px;
    color: #666;
    font-size: 18px;
    text-transform: none;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;

    &:hover {
	border: 4px solid #006c94;
	background-color: #006c94;
	color: #fff;
    }

    .IconAdjust {
	vertical-align: middle;
	margin-bottom: 2px;
	margin-right: 5px;
    }
}
