@import "../../../mixins.scss";

.Circus2020 {
    background-image: url('../../../assets/images/circus/2020/background.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    .Intro {
	padding: 100px 0;
	background: linear-gradient(180deg, rgba(9,133,23,1) 30%, rgba(24,161,20,1) 80%);

	h1 {
	    text-align: center;
	    font-size: 3.5rem;
	    max-width: 700px;
	    color: #fff;
	    font-weight: 600;
	}

	h2 {
	    text-align: center;
	    font-size: 1.5em;
	    max-width: 700px;
	    color: #fff;
	    margin: 2rem 0 2.2rem;
	    font-weight: 400;
	}

	h4 {
	    color: #fff;
	    max-width: 700px;
	    text-align: center;
	    font-weight: 400;
	    font-size: 1.5em;
	    margin: 2rem 0;
	    line-height: 1.4em;
	}

	.BuyLink {
	    display: block !important;
	    margin: 0 auto !important;
	    padding: 0.15rem 1rem 0.15rem 1rem !important;
	    border: 1px solid #fff !important;
	    font-size: 1rem !important;
	    letter-spacing: 0.05rem !important;
	    transition: color 0.2s ease-out, border 0.3s ease-out;
	    border-radius: 1.8rem !important;
	    border: solid #fff 2px !important;
	    width: 140px !important;
	    min-height: 1.8rem !important;
	    text-transform: capitalize !important;
	    color: #fff !important;

	    &:hover {
		border-color: rgb(0, 51, 102) !important;
		color: rgb(0, 51, 102) !important;
	    }
	}

    }

    .Hero {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	height: 52.7vw;
	width: 100vw;
	padding: 0;
	background-image: url('../../../assets/images/circus/2020/circo2020.jpg');
    }


    .PhotoGallery {
	background-color: rgba(0, 80, 100, 0.5);
	color: #fafafa;

	h1 {
	    font-size: 28px;
	    text-align: center;

	    @include gt-xs {
		font-size: 36px;
	    }
	}
    }

}
