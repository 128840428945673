.Slide {

    width: 100%;
    aspect-ratio: 16/9;
    cursor: pointer;
    
    img {
	max-height: 100%;
	object-fit: cover;
    }
}

.LightBox {
    z-index: 10000000;
}
