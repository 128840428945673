@import "../../mixins.scss";

.Wrapper {
    margin-top: 40px;
    padding: 0 5px;

    .SectionName {
	font-weight: 400;
	font-size: 45px;
	line-height: 1.05;
	text-align: center;
    }

    .Arrow {
	width: 50px;
	cursor: pointer;

	svg {
	    font-size: 35px;
	}

	.Disabled {
	    color: #808080;
	    cursor: default;
	}
    }

    .Carousel {
	width: calc(100% - 100px);
    }

    .Image {
	opacity: 0.85;
	@include Transition300;

	img {
	    width: 100%;
	    aspect-ratio: 5/4;

	    object-fit: cover;
	}

	&:hover {
	    opacity: 1;
	}

	a {
	    display: block;
	    height: 100%;
	}
    }

    .Name {
	font-size: 42px;
	text-align: center;
	@include Truncate;

	a {
	    text-decoration: none;
	    color: inherit;

	    &:hover {
		text-decoration: underline;
	    }
	}
    }

    .OutlineButtonWrapper {
	width: calc(100% - 100px);
    }

}
