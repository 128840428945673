@import "../../mixins.scss";

.Offering {
    padding-top: 25px;
    padding-bottom: 25px;

    @include gt-xs {
	padding-top: 50px;
	padding-bottom: 50px;
    }
    
    .Name {
	line-height: 1.2;
	margin-bottom: 15px;
    }

    .Area {
	margin-top: -10px;
	text-transform: uppercase;
	margin-bottom: 15px;
    }

    .Description {

	@include xs {
	    margin-top: 20px;
	}
    }
    
    .Promos {

	padding-top: 20px;
	
	.Promo {
	    img {
		width: 100%;
	    }
	}

    }
}
