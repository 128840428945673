@import "../../variables.scss";

.MobileHeader {
    background-color: #000000;
    height: $header-height;
    position: fixed;
    top: 0;
    z-index: 10000;

    img {
	max-width: 70%;
    }

    svg {
	color: #ffffff;
	margin-right:20px;
    }
}
