@import "../../variables.scss";
@import "../../mixins.scss";

.Hero {
    background-size: cover;
    aspect-ratio: 414/275;

    @include gt-xs {
	background-size: cover;
	aspect-ratio: 1440/480;
    }
}
