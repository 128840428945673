.CircusRow {
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    justify-content: center;
    align-items: center;
    text-align: center;


    h1 {
        color: #FFFFFF;
        font-size: 3.5rem;
        margin-top: 3em;
        text-align: center;
        font-weight: 600;
        line-height: 1.2em;
        margin-bottom: 1em;
        margin-top: 0;
    }

    h2 {
        color: #fff;
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.5rem;
	font-weight: 300;
    }

    h3 {
        font-size: 1.5em;
        display: block;
        color: #FFFFFF;
        max-width: 1000px;
        margin-top: 2rem;
        -ms-grid-row-align: center;
        align-self: center;
        text-align: center;
        font-weight: 400;
        line-height: 1.4em;
        margin-bottom: 2rem;
	max-width: 700px;
	padding: 0 25px;
    }

    p {
        color: rgb(255, 255, 255);
        font-size: 16px;
        max-width: 1000px;
        text-align: center;
        line-height: 1.5em;
        font-weight: 400;
    }
}

.BuyLink {
    display: block !important;
    margin: 0 auto !important;
    padding: 0.15rem 1rem 0.15rem 1rem !important;
    border: 1px solid #fff !important;
    font-size: 1rem !important;
    letter-spacing: 0.05rem !important;
    transition: color 0.2s ease-out, border 0.3s ease-out;
    border-radius: 1.8rem !important;
    border: solid #fff 2px !important;
    width: 140px !important;
    min-height: 1.8rem !important;
    text-transform: capitalize !important;
    color: #fff !important;

    &:hover {
	border-color: rgb(0, 51, 102) !important;
	color: rgb(0, 51, 102) !important;
    }
}

.Color0 {
    background-color: #214997;
}

.Color1 {
    background-color: rgba(221, 21, 232, 0.5);
}

.Color2 {
    background-color: rgba(21, 176, 232, 0.5);
}

.Color3 {
    background-color: rgba(70, 232, 21, 0.5);
}

.Color4 {
    background-color: rgba(232, 21, 21, 0.5);
}

.Color5 {
    background-color: rgba(37, 14, 128, 0.5);
}

.Color6 {
    background-color: rgba(60, 232, 0, 0.5);
}

.Color7 {
    background-color: rgba(232, 158, 21, 0.5);
}

.Color8 {
    background-color: rgba(90, 155, 33, 0.5);
}

.Color9 {
    background-color: rgba(150, 23, 180, 0.5);
}

.Color10 {
    background-color: rgba(255, 0, 125, 0.5);
}

.Color11 {
    background-color: rgba(33, 33, 33, 0.5);
}

.Color12 {
    background-color: rgba(0, 80, 100, 0.5);
}

.Color13 {
    background-color: rgb(9,133,23);
    background: linear-gradient(180deg, rgba(9,133,23,1) 30%, rgba(24,161,20,1) 80%);
}
