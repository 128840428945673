@import "../../mixins.scss";

.PhotoGallery {
    
    .Tile {
	aspect-ratio: 3/2;
	width: 100%;
	cursor: pointer;
	position: relative;

	img {
	    width: 100%;
	    height: 100%;
	    object-fit: cover; 
	}

	.More {
	    position: absolute;
	    height: calc(100% - 4%);
	    width: calc(100% - 2%);
	    display: flex;
	    justify-content: center;
	    align-items: center;

	    span {

		@include xs {
		    font-size: 48px;
		}
		
		margin: 0 auto;
		font-size: clamp(64px, 68px, 72px);
		color: #ffffff;
		text-shadow: 2px 2px 5px #111111;
	    }
	}
    }
}

.LightBox {
    z-index: 10000000;
}
