@import "../../../mixins.scss";

.Circus2021 {
    background-image: url('../../../assets/images/circus/2021/background.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    .Intro {


	h1 {
	    text-align: center;
	    font-size: 3.5rem;
	    max-width: 700px;
	    color: #fff;
	    font-weight: 600;
	}

	h2 {
	    text-align: center;
	    font-size: 1.5em;
	    max-width: 90%;
	    color: #fff;
	    margin: 2rem 0 2.2rem;
	    font-weight: 400;

	    @include gt-xs {
		max-width: 700px;
	    }

	}

	h4 {
	    color: #fff;
	    max-width: 700px;
	    text-align: center;
	    font-weight: 400;
	    font-size: 1.5em;
	    margin: 2rem 0;
	    line-height: 1.4em;
	}

	.BuyLink {
	    display: block !important;
	    margin: 0 auto !important;
	    padding: 0.15rem 1rem 0.15rem 1rem !important;
	    border: 1px solid #fff !important;
	    font-size: 1rem !important;
	    letter-spacing: 0.05rem !important;
	    transition: color 0.2s ease-out, border 0.3s ease-out;
	    border-radius: 1.8rem !important;
	    border: solid #fff 2px !important;
	    width: 140px !important;
	    min-height: 1.8rem !important;
	    text-transform: capitalize !important;
	    color: #fff !important;

	    &:hover {
		border-color: rgb(0, 51, 102) !important;
		color: rgb(0, 51, 102) !important;
	    }
	}

	padding-bottom: 100px;

	@include gt-xs {
	    padding: 100px 0;
	}

    }

    .Hero {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: 100vw;
	height: 100vw;
	box-sizing: border-box;
	padding-top: 200px;
	background-image: url('../../../assets/images/circus/2021/circo2021_mobile.png');

	@include gt-xs {
	    height: 52.7vw;
	    width: 100vw;
	    background-image: url('../../../assets/images/circus/2021/circo2021.png');
	}
    }


    .PhotoGallery {
	background-color: rgba(0, 80, 100, 0.5);
	color: #fafafa;

	h1 {
	    font-size: 28px;
	    text-align: center;

	    @include gt-xs {
		font-size: 36px;
	    }
	}
    }

}


.LastRow {
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(255,0,125,0.5);

    h1 {
        color: #FFFFFF;
        font-size: 3.5rem;
        margin-top: 3em;
        text-align: center;
        font-weight: 600;
        line-height: 1.2em;
        margin-bottom: 1em;
        margin-top: 0;
    }

    p {
	font-size: 1.5rem;
	line-height: 1.4em;
        color: rgb(255, 255, 255);
        max-width: 1000px;
        text-align: center;
        font-weight: 400;
	margin-block-start: 0;
	margin-block-end: 0;

	span {
	    text-transform: uppercase;
	    font-size: 70%;
	}

	&:last-of-type {
	    margin-bottom: 80px;
	}
    }


}
