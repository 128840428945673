@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin-top: $header-menu-height;

    @include gt-xs {
	max-width: $content-max-width;
	margin: 0 auto;
	margin-top: $header-menus-height + 50px;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	a {
	    text-decoration: none;
	    color: #000000;
	    font-weight: 600;

	    &:hover {
		text-decoration: underline;
	    }
	}

	section {
	    margin: 20px 0;

	    img {
		max-width: 100%;
		margin: 25px 0 50px 0;
	    }

	    h6 {
		margin: 40px 0 20px 0;
	    }

	}

	.PageImage {

	    margin: 0 auto 20px 0;
	    max-width: calc(100% - 40px);

	    @include gt-xs {
		margin: 0;
		float: right;
		margin-left: 20px;
		top: 0;
		position: relative;
	    }
	}

    }

}
