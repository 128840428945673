@import "../../mixins.scss";

.Footer {
    

    @include gt-xs {
	margin-top: 50px;
    }
    
    img {
	width: 24.25%;
	cursor: pointer;
	padding-right: 0.75%;
	padding-bottom: 0.75%;

	@include xs {
	    width: 45%;
	    padding-right: 2%;
	    padding-bottom: 2%;
	}
    }
}
