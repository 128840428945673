@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {

    section {
	max-width: $content-max-width;
	margin: 50px 0;
	padding: 0 $padding-mobile;

	@include gt-xs {
	    padding: 0 $padding-desktop;
	}

	img {
	    width: 100%;
	}
    }
}
