@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    background-color: #E5E5E5;
    padding: 10px $padding-mobile 20px $padding-mobile;

    @include gt-xs {
	padding: 10px $padding-desktop 20px $padding-desktop;
    }


    section {
	max-width: $content-max-width;

	.SupportersLogos {
	    order: 2;
	    text-align: center;

	    @include gt-xs {
		order: 1;
	    }


	    img {
		max-width: 85%;
		margin: 10px;

		@include gt-xs {
		    max-width: 100%;
		    margin: 20px 20px 20px 0;
		}
	    }
	}

	.SocialNetworks {
	    order: 2;

	    @include gt-xs {
		order: 3;

	    }
	    img {

		padding: 20px 10px;
		max-width: 24px;

		@include gt-xs {
		    padding: 20px 10px;
		    max-width: 32px;
		}
	    }

	}

	.Policies {
	    order: 3;
	    width: 100%;

	    span {

		@include xs {
		    width: 100%;
		    text-align: center;
		    margin-bottom: 5px;

		    &:first-child {
			margin-top: 10px;
		    }
		}

		@include gt-xs {
		    margin-right: 40px;
		}

		a {
		    color: inherit;
		    text-decoration: none;

		    &:hover {
			text-decoration: underline;
		    }
		}
	    }

	    @include gt-xs {
		order: 3;
	    }

	}
    }
}
