@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin: 50px 0;

    section {
	max-width: $content-max-width;
	padding: 0 $padding-mobile;

	@include gt-xs {
	    padding: 0 $padding-desktop;
	}


	.Third {
	    width: calc((100% - 50px) / 3);
	}

	.Name {
	    text-align: center;
	    font-size: 42px;
	    @include Truncate;

	    a {
		text-decoration: none;
		color: inherit;

		&:hover {
		    text-decoration: underline;
		}
	    }
	}

	.Text {
	    a {
		text-decoration: none;
		color: inherit;

		&:hover {
		    text-decoration: underline;
		}
	    }

	}

	.Placeholder {

	    img {
		max-width: 100%;
		aspect-ratio: 5/4;
		object-fit: cover;
		@include image-opacity-transition;
	    }
	}
    }
}
