@import '../../mixins.scss';

.PostPreview {
    position: relative;
    cursor: pointer;
    opacity: 0.85;
    color: white;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include Transition300;

    &:hover {
	opacity: 1;
    }

    a {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
    }

    .PostPreviewImage {
	padding-top: 75%;
	background-size: cover;
    }

    .PostPreviewDetails {
	background-color: #262626;
	padding-bottom: 20px;
	flex-grow: 1;

	.PostName {
	    margin: 0 25px;
	    line-height: 1.2em;
	    font-weight: 400;
	    padding-top: 20px;
	}

	.Action {
	    align-self: flex-end;
	}

	.PlusIcon {
	    color: #6eb0ff;
	}
    }
}
