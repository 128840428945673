@import "../../mixins.scss";

.Menu {
    font-family: 'Colisseum' !important;
    font-size: 19px;
    font-weight: 400;
    margin-block-end: 0;
    @include font-feature-settings;
}

.Body0 {
    font-family: "roc-grotesk", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 50px;
}

// manter sicronizado com o tema
.H1colisseum {
    font-family: 'Colisseum';
    font-size: 40px;
    line-height: 1.22;
    font-weight: 700;
    margin-bottom: 36px !important;
    @include font-feature-settings;

    @gt-xs {
	font-size: 56px;
    }

}

// Colisseum

// regular

.ColisseumRegular20 {
    font-family: 'Colisseum' !important;
    font-weight: 400;
    font-size: 20px;
    @include font-feature-settings;
}

.ColisseumRegular24 {
    font-family: 'Colisseum' !important;
    font-weight: 400;
    font-size: 24px;
    @include font-feature-settings;
}

.ColisseumRegular27 {
    font-family: 'Colisseum' !important;
    font-weight: 400;
    font-size: 27px;
    @include font-feature-settings;
}

.ColisseumRegular30 {
    font-family: 'Colisseum' !important;
    font-weight: 400;
    font-size: 30px;
    @include font-feature-settings;
}

.ColisseumRegular40 {
    font-family: 'Colisseum' !important;
    font-weight: 400;
    font-size: 40px;
    @include font-feature-settings;
}

.ColisseumRegular44 {
    font-family: 'Colisseum' !important;
    font-weight: 400;
    font-size: 44px;
    @include font-feature-settings;
}

.ColisseumRegular48 {
    font-family: 'Colisseum' !important;
    font-weight: 400;
    font-size: 48px;
    @include font-feature-settings;
}

.ColisseumRegular58 {
    font-family: 'Colisseum' !important;
    font-weight: 400;
    font-size: 58px;
    @include font-feature-settings;
}

// bold

.ColisseumBold44 {
    font-family: 'Colisseum' !important;
    font-weight: 700;
    font-size: 44px;
    @include font-feature-settings;
}

.ColisseumBold48 {
    font-family: 'Colisseum' !important;
    font-weight: 700;
    font-size: 48px;
    @include font-feature-settings;
}


// Roc Grotesk

// regular

.RocGroteskRegular14 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.RocGroteskRegular16 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.RocGroteskRegular18 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-size: 18px;
}

.RocGroteskRegular20 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.RocGroteskRegular25 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-size: 25px;
}

.RocGroteskRegular30 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
    font-size: 30px;
}

// medium

.RocGroteskMedium16 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.RocGroteskMedium18 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.RocGroteskMedium20 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-size: 20px;
}

.RocGroteskMedium25 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
    font-size: 25px;
}

// bold

.RocGroteskBold16 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.RocGroteskBold18 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.RocGroteskBold20 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.RocGroteskBold25 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 700;
    font-size: 25px;
}

.RocGroteskBold30 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 700;
    font-size: 30px;
}

.RocGroteskBold33 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 700;
    font-size: 33px;
}
