@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {
    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;
    }
}



// activity name
.Name {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 36px;
    text-transform: uppercase;
    text-align: left;

    @include gt-xs {
	font-size: 56px;
    }

}


.DetailsAndAreasWrapper {
    margin-top: 50px;
}

#Details {

    font-size: 24px;

    span {
	text-transform: uppercase;
	font-weight: bold;
    }

    p {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-bottom: 10px;
    }
}

.ActivityAreas {

    margin-top: 30px;

    img {
	width: 140px;
    }

}

.Description {

    margin-top: 50px;

    p {
	margin-block-start: 0;
	margin-block-end: 0;
	font-size: 24px;
	margin-bottom: 24px;
    }
}


.A {

    order: 2;

    @include gt-xs {
	order: 1
    }


}

.B {

    order: 1;

    @include gt-xs {
	order: 2
    }

}

.BackToSchool {

    @include xs {
	margin-left: 20px;
    }
}
