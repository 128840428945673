@import "../../variables.scss";
@import "../../mixins.scss";

.EducationalHero {
    background-size: cover;
    aspect-ratio: 20/9;

    @include gt-xs {
	background-size: cover;
	aspect-ratio: 4;
    }

}
