@import "../../variables.scss";

.Wrapper {
    margin-top: $subsubmenu-height;

    .ArchiveView {
	margin-top: 40px;
	max-width: $content-max-width;
	padding: 0 30px;
	box-sizing: border-box;
    }
}
