@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {

    margin-top: $header-height;

    @include gt-xs {
	margin-top: $header-menu-height + $menu-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 80px 40px 60px;
	}

	width: 100%;
	max-width: $content-max-width;


	.Description {
	    margin-top: 25px;
	    line-height: 1.2;

	    @include gt-xs {
		margin-top: 50px;
	    }
	}

	.Contacts {
	    
	    margin: 50px 0;
	    
	    @include gt-xs {
		margin: 100px 0;
	    }
	    
	    .InformationWrapper {

		order: 2;
		
		@include gt-xs {
		    order: 1
		}
		
		.Information {
		    margin-top: 25px;
		    margin-bottom: 25px;

		    a {
			text-decoration: none;
			color: #000000;

			&:hover {
			    text-decoration: underline;
			}
		    }
		}
	    }
	    .Banner {
		order: 1;

		@include gt-xs {
		    order: 2;
		}
		
		img {
		    width: 100%;

		    @include gt-xs {
			width: 90%;
		    }
		}
	    }
	}

    }
}
