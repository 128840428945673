@import "../../mixins.scss";

.EventPreview {
    background: rgba(255, 255, 255, 0.8);
    margin: 1px;
    padding: 15px;
    cursor: pointer;
    position: relative;
    height: 100%;
    max-height: calc(100% - 2px);
    max-width: calc(100% - 2px);
    transition: all 1s ease-out;

    p {
	color: #000;
	margin: 0;
	@include Transition300;

	&.Name {
	    font-size: 1.4em;
	    line-height: 1.1em;
	    overflow: hidden;
	    text-overflow: ellipsis;
	}

	&.Date {
	    font-size: 14.4px;
	}

    }

    &:hover {
	background-color: #fff;
    }

    &:hover p, &:hover .Actions svg {
	opacity: 1;

    }

    .EventLink {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0
    }

    .Actions {
	z-index: 9999;

	svg {
	    font-size: 25px;
	    color: #000;
	    @include Transition300
	}
    }
}

.Soldout {

    p {
	color: #666;
	opacity: 1;
    }

    .Actions {
	svg {
	    color: #666;
	    opacity: 1;
	}
    }

}
