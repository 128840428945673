@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {
    margin-top: $header-height - 40px;

    @include gt-xs {
	margin-top: $header-menu-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;
    }
}


.Enrollments {

    h3 {
	text-transform: uppercase;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-bottom: 5px;
	margin-top: 40px;
	font-size: 24px;
    }
}

p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
}

.Form {
    margin-bottom: 500px;

    form {
	p > span {
	    font-size: 18px;
	    font-weight: bold;
	    line-height: 24px;
	}

	p > div {
	    margin-top: 10px;
	}
    }

}

.ToogleConditions {

    margin-top: 25px;
    margin-bottom: 25px;

    span {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: bold;
	vertical-align: middle;
    }

    svg {
	vertical-align: middle;
    }
}

.Underlined {
    text-decoration: underline;
}

.Logo {
    margin-left: -20px;
    margin-top: -50px;
    margin-bottom: 25px;

    img {
	width: 80%;
    }
}
