@import "../../../mixins.scss";
@import "../../../variables.scss";

$circus-red: #EC2926;

@mixin roc-grotesk-300 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 300;
}

@mixin roc-grotesk-400 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 400;
}

@mixin roc-grotesk-500 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 500;
}

@mixin roc-grotesk-700 {
    font-family: "roc-grotesk", sans-serif;
    font-weight: 700;
}

@mixin roc-grotesk-wide-700 {
    font-family: "roc-grotesk-wide", sans-serif;
    font-weight: 700;
}

.Hero {
    background-size: cover;
    background-image: url('../../../assets/images/circus/2023/hero.jpg');
    aspect-ratio: 1.9;
    margin-top: $header-menu-height;

    @include gt-xs {
	margin-top: $header-menus-height;
    }
}

.SectionTitle {
    margin-top: 50px;
    margin-bottom: 50px;

    img {
	width: 100%;
    }
}

.Circus2023 {
    background-color: #FFF4E1;
    color: $circus-red;
    padding: 25px;

    @include gt-xs {
	padding: 100px 300px;
    }

    p, h5 {
	margin-block-start: 0;
     	margin-block-end: 0;
    }

    h2 {
	text-align: center;
	@include roc-grotesk-wide-700;
	text-transform: uppercase;
	font-size: 40px;
	margin-top: 50px;
	margin-top: 50px;
    }

    .Intro {

	p {
	    @include roc-grotesk-400;
	    font-size: 30.83px;
	    line-height: 44.39px;
	    margin-bottom: 25px;
	    text-align: center;
	}

	.Credits {

	    margin-top: 50px;

	    h5 {
		@include roc-grotesk-500;
		text-align: center;
		font-size: 25.15px;
		line-height: 36.22px;
	    }

	    p {
		@include roc-grotesk-500;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 10px;
		font-size: 38.6px;
		line-height: 55.58px;
	    }
	}
    }


    .Sessions {
	margin-top: 100px;


	.Call2Action {
	    margin-top: 50px;
	    margin-bottom: 50px;

	    button {
		background-color: $circus-red;
		color: #ffffff;

		@include gt-xs {
		    padding: 10px 100px;
		}

		span {
		    @include roc-grotesk-500;

		    line-height: 35.51px;

		    @include gt-xs {
			font-size: 24.66px;
		    }
		}
	    }
	}


	p {
	    @include roc-grotesk-300;
	    text-align: center;
	    margin-bottom: 25px;
	    font-size: 24.66px;
	    line-height: 35.51px;
	}

	.Discounts {
	    p {
		margin-bottom: 0;
	    }
	}

    }

    .Artists {

	margin-top: 100px;

	h2 {
	    text-align: center;
	}

	p {
	    @include roc-grotesk-700;
	    font-size: 21.07;
	    line-height: 30.35px;
	    text-align: center;
	    border-top: 1px solid $circus-red;
	    text-transform: uppercase;
	    padding: 10px 0;

	    &:last-of-type {
		border-bottom: 1px solid $circus-red;
	    }
	}
    }

    .Staff {
	margin-top: 100px;

	h2 {
	    text-align: center;
	}


	p {

	    text-align: center;
	    @include roc-grotesk-400;
	    font-size: 20.14px;
	    line-height: 29px;

	    b {
		text-transform: uppercase;
		@include roc-grotesk-700;
		font-size: 18.55px;
		line-height: 26.72px;
	    }

	}

	.TechTeam {
	    margin-top: 50px;
	}

	.Coordination {
	    margin-top: 50px;
	    margin-bottom: 25px;
	}
    }

    .Footer {
	margin-top: 25px;

	@include gt-xs {
	    margin-top: 100px;
	}

	img {
	    width: 100%;
	}
    }
}
