.Custom {
    padding: 3px 25px;
    color: #000000 !important;
    border: 4px solid #000000;
    font-size: 18px;
    text-decoration: none;

    &:hover {
	text-decoration: underline;
    }
}

.Thin {
    border: 1px solid #000000;
}
