.Consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px;
    background-color: hsla(0,0%,99%,.9);
    z-index: 100000;

    p {
	max-width: 980px;
	text-align: center;
	color: #222;
	line-height: 130%;

	a {
	    color: #006c94;
	    text-decoration: none;
	}
    }

    .Accept {
	background-color: #0099fb;

	&:hover {
	    background-color: #0099fb;
	}
    }
}
