.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    padding: 5px 25px;
    margin-bottom: 5px;
    text-align: center;
    background-color: transparent;
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        border: 1px solid #808080;
	text-decoration: underline;
    }

    &.Link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.FullWidth {
    width: 100%;
    padding: 5px;
}
