@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

    }

    .Introduction {

	.Banner{

	    order: 2;
	    margin-top: 25px;

	    @include gt-xs {
		margin-top: 0;
		order: 1;
	    }

	    img {
		max-width: 100%;

		@include gt-xs {
		    max-width: 93%;
		}
	    }
	}

	.Content {
	    order: 1;

	    p {
		font-size: 16px;
	    }

	    @include gt-xs {
		order: 2;

		p {
		    font-size: 20px;
		}

	    }

	    a {
		text-decoration: none;

		&:hover {
		    text-decoration: underline;
		    color: #ffffff;
		}
	    }

	}
    }

}

.ActivityAreas {

    margin-top: 50px;

    @include gt-xs {
	margin-top: 100px;
    }

    img {
	width: 100%;
    }
}
