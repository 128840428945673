@import "../../mixins.scss";

.SocialNetworks {
    img {
	padding: 20px 10px;
	max-width: 32px;

	@include gt-xs {
	    padding: 20px 10px;
	    max-width: 36px;
	}
    }
}
