@import "../../mixins.scss";
@import "../../variables.scss";

.SectionBanner {
    margin-top:$header-height;
    aspect-ratio: 20/9;
    background-size: cover;
    background-image: url("../../assets/images/tours/tours_banner_mobile.webp");

    @include gt-xs {
	margin-top: $header-menu-height;
	aspect-ratio: 3;
	background-size: cover;
	background-image: url("../../assets/images/tours/tours_banner_desktop.webp");
    }

}

.Tours {
    margin-top: 50px;
    
    h1 {
	text-align: center;
	text-transform: uppercase;
	font-size: 36px;
	margin-block-end: 0;

	@include gt-xs {
	    font-size: 48px;
	    margin-top: 100px;
	}
    }

    .CallToAction {
	text-align: center;
	max-width: 475px;
	margin-top: 20px;
	margin-bottom: 40px;

	@include gt-xs {
	    margin: 20px auto 80px auto;
	}

	p {
	    margin-block-start: 0;
	    margin-block-end: 0;
	    font-size: 20px;
	    line-height: 28px;

	    @include gt-xs {
		font-size: 30px;
		line-height: 38px;
	    }
	}
    }

    .Action {
	margin-bottom: 50px;
	
	@include gt-xs {
	    margin-bottom: 200px;
	    
	}
    }

}
