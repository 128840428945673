.EduActivityPreview {
    width: 300px;
    margin: 10px 25px 50px 0px;
    cursor: pointer;

    img {
	width: 100%;
	image-rendering: optimizeQuality;
    }

    .Category {
	margin-top: 10px;
	max-width: 100%;
	text-transform: uppercase;
	font-weight: bold;
    }

    .ActivityAreas {
	img {
	    max-width: 33%;
	}
    }
}
