@import "../../variables.scss";
@import "../../mixins.scss";

.Submenu {
    top: $header-height;

    @include gt-xs {
	top: $header-height + $menu-height;
    }

    position: fixed;
    background-color: #fff;
    height: $submenu-height;
    border-bottom: 1px solid #000000;
    z-index: $submenu-z-index;
    // scrollable horizontal menu
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    a {
	margin: 0 20px;
	text-decoration: none;
	color: #222222;
	opacity: .7;
	white-space: nowrap;

	&:hover {
	    text-decoration: underline;
	}
    }

    .Active {
	text-decoration: underline;
	font-weight: bold;
	opacity: 1;
    }
}
