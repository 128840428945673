@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {

    margin-top: $header-menu-height;

    @include gt-xs {
	margin: 0 auto;
	margin-top: $header-menus-height;
    }


    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	h6 {
	    margin-bottom: 20px;
	}

	.PageImage {
	    float: right;
	    margin-left: 20px;
	    margin-bottom: 20px;
	    max-width: 100%;

	    @include gt-xs {
		float: right;
		margin-left: 40px;
		margin-bottom: 20px;
		max-width: 33%;
	    }
	}

	.TextImage {
	    margin: 40px 0;
	    overflow: auto;
	}

	.RowImage {

	    img {
		max-width: 100%;
	    }
	}

	.RowOfImages {
	    margin: 50px 0;

	    div {
		margin-right: 10px;

		img {
		    max-width: 100%;
		}
	    }

	    div:last-of-type {
	    	margin-right: 0;
	    }
	}


    }
}
