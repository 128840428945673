@import "../../../mixins.scss";

.Star {
    background-color: #1A0700;
    padding-bottom: 25px;
}

.Circus2022 {
    background-color: #1A0700;
    .Intro {


	.Row {
	    padding-bottom: 75px;
	}

	.Row2 {
	    padding-bottom: 250px;
	}

	h1 {
	    padding: 50px 0 10px 0;
	    color: #fff;
	    font-size: 3.5rem;
	    max-width: 700px;
	    margin-left: auto;
	    margin-right: auto;
	    line-height: 1.2em;
	    font-weight: 600;
	    text-align: center;
	}

	h2 {
	    max-width: 700px;
	    margin-left: auto;
	    margin-right: auto;
	    font-size: 1.5rem;
	    line-height: 1.4em;
	    color: #FFF;
	    font-weight: 400;
	    text-align: center;
	    padding-bottom: 20px;
	}

	p {
	    color: #ffffff;
	    font-size: 1.5rem;
	    font-weight: 400;
	    max-width: 700px;
	    margin-left: auto;
	    margin-right: auto;
	    line-height: 1.4em;
	    text-align: center;
	}
    }


    .BuyLink {
	display: block !important;
	margin: 0 auto !important;
	padding: 0.15rem 1rem 0.15rem 1rem !important;
	border: 1px solid #fff !important;
	font-size: 1rem !important;
	letter-spacing: 0.05rem !important;
	transition: color 0.2s ease-out, border 0.3s ease-out;
	border-radius: 1.8rem !important;
	border: solid #fff 2px !important;
	width: 140px !important;
	min-height: 1.8rem !important;
	text-transform: capitalize !important;
	color: #fff !important;

	&:hover {
	    border-color: rgb(255, 255, 106) !important;
	    color: rgb(255, 255, 106) !important;
	}
    }

    section {
	padding: 25px;

	@include gt-xs {
	    padding: 100px;
	}

	h2 {
	    font-size: 3.5rem;
	    color: #ffffff;
	    text-align: center;
	}

	p {
	    margin: 0 auto;
	    max-width: 700px;
	    font-size: 24px;
	    color: #ffffff;
	    font-weight: 400;
	    margin-block-start: 0;
	    margin-block-end: 0;
	    text-align: center;
	    line-height: 1.4em;

	    a {
		text-decoration: underline;
		color: #ffffff;
	    }
	}

	.Spacing {
	    margin-bottom: 50px;
	}
    }

    .TechnicalSheet {
	background-color: rgba(33,33,33,0.5);
    }

    .ArtisticSheet {
	background-color: rgb(9,133,23);
    }

}

.Hero {
    background-size: cover;
    height: 100vh;
    background-position: center top;
    background-image: url('../../../assets/images/circus/2022/hero.jpg');
}
