@import "../../variables.scss";

.Menu {
    top: $header-height;
    position: fixed;
    background-color: #ffffff;
    min-height: $menu-height;
    border-bottom: 1px solid #000000;
    z-index: $menu-z-index;

    a {
	margin: 0 20px;
	text-decoration: none;
	color: #222;
	opacity: .7;

	&:hover {
	    text-decoration: underline;
	}
    }

    .Active {
	text-decoration: underline;
	font-weight: bold;
	opacity: 1;
    }
}
