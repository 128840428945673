$header-height: 120px;
$menu-height: 40px;
$submenu-height: 40px;
$subsubmenu-height: 40px;
$header-menu-height: $header-height + $menu-height;
$header-menus-height: $header-height + $menu-height + $submenu-height;
$header-submenu-height: $header-height + $submenu-height;

// z-index
$menu-z-index: 1299;
$submenu-z-index: 1299;
$subsubmenu-z-index: 1299;
$modal-on-top-z-index: 10000;

// colors
$main: #008793;
$secondary: rgb(220, 249, 4);
$background: #e5e5e5;
$link-color: #000000;

// others
$content-max-width: 1280px;
$padding-desktop: 40px;
$padding-mobile: 20px;
