@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin-top: $header-height;

    @include gt-xs {
	margin-top: $header-menu-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	.ValidationError {
	    padding-top: 25px;
	    color: rgb(221, 44, 0);
	    font-size: smaller;
	}

	.Success {
	    padding-top: 25px;
	    font-size: smaller;
	}
    }
}
