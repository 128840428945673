@import "../../mixins.scss";
@import "../../variables.scss";

.Wrapper {

    margin-top: $header-height + $menu-height;

    @include gt-xs {
	margin-top: $header-menu-height + $menu-height;
    }

    main {

	@include gt-xs {
	    padding: 80px 40px 60px;
	}

	width: 100%;
	max-width: $content-max-width;

	#Introduction {
	    margin-bottom: 80px;
	    line-height: 1.3;

	    @include xs {
		padding: 80px 20px 0px 20px;
		margin-bottom: 0px;
	    }

	    .Logo {
		width: 100%;

		img {
		    max-width: 300px;

		    @include xs {
			max-width: 55%;
			margin-bottom: 20px;
		    }
		}

		@include xs {
		    margin-top: -25px;
		    margin-bottom: 40px;
		    text-align: center;
		}
	    }

	    .Blurb {
		@include gt-xs {
		    padding-left: 100px;
		}
	    }

	}

	#Presentation {
	    margin-bottom: 100px;
	    line-height: 1.3;
	    
	    p {
		font-size: 24px;
		margin-block-start: 0;
		margin-block-end: 0;

		@include xs {
		    font-size: 18px;
		}
	    }

	    @include xs {
		padding: 10px 20px 20px 20px;
		margin-top: 25px;
		margin-bottom: 60px;
	    }
	}

	#Activities {
	    margin-bottom: 30px;

	    @include gt-xs {
		margin-bottom: 150px;
	    }
	}
	
	#LastEdition {
	    margin-bottom: 45px;

	    #Report {
                order: 2;

                @include xs {
                    padding: 20px;
                }

                @include gt-xs{
                    order: 1
                }
            }

            #Video {

		order: 1;

		@include xs {
		    padding: 20px;
		}

		@include gt-xs{
		    order: 2;
		    padding-left: 100px;
		}

		& > div:first-of-type {
		    margin-bottom: 25px;
		}
	    }
	}

	#Promoters {
	    @include xs {
		img {
		    margin-top: -25px;
		    max-width: 85%;
		    margin-bottom: 60px;
		}
	    }
	}
    }
}

.LightBox {
    z-index: 10000000;
}
