@import "../../variables.scss";
@import "../../mixins.scss";

$padding-left: 40px;

@mixin header-style {
    text-align: center;
    padding: 5px 0;

    @include gt-xs {
	text-align: left;
	padding-left: $padding-left;
    }
}

@mixin text-style {
    text-align: center;
    margin-block-start: 0;
    margin-block-end: 0;

    @include gt-xs {
	text-align: left;
	padding-left: $padding-left;
    }
}

.Wrapper {

    margin-top: $header-height;
    
    @include gt-xs {
	margin-top: $header-menu-height;
    }

    main {
	padding: 20px;

	@include gt-xs {
	    padding: 60px 40px;
	}

	width: 100%;
	max-width: $content-max-width;

	.SpaceName {

	    text-align: center;
	    
	    img {
		width: 60%;
	    }
	    
	    padding: 25px 0 25px 0;

	    @include gt-xs {
		text-align: left;
		padding: 0 0 25px 0;
	    }

	    p {
		@include text-style;
	    }

	}

	.Suggestions {

	    @include xs {
		margin-top: 25px;
	    }
	    
	    h3 {
		text-transform: uppercase;
		line-height: 1.3;
	    }

	    p {
		@include text-style;
		padding: 20px 0;
	    }
	}

	.TopInfographic {

	    margin-bottom: 25px;
	    
	    img {
		width: 100%;
	    }
	}

	.BottomInfographic {

	    margin-top: 25px;
	    
	    img {
		width: 100%;
	    }
	}


	.MoreSpacesWrapper {
	    @include xs {
		margin: 30px 0;
	    }
	}
	
	.Description {

	    margin-top: 25px;
	    margin-bottom: 25px;

	    @include gt-xs {
		margin-top: 40px;
		margin-bottom: 0;
	    }

	    h3 {
		@include header-style;
	    }

	    p {
		margin-block-start: 0;
		margin-block-end: 0;

		@include gt-xs {
		    text-align: left;
		}
	    }
	}

	.Actions {

	    @include xs {
		margin-top: 50px;
	    }
	    
	    @include gt-xs {
		max-width: 80%;
	    }
	    
	    h3 {
		@include header-style;
		
	    }

	    p {
		@include text-style;
	    }

	    .ButtonWrapper {
		margin: 20px 0;

	    }
	}

	.BackButton {
	    margin: 50px;
	}

    }
}
