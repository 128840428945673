@import "../../variables.scss";

.MobileMenu {
    background-color: #ffffff;
    height: 100vh;

    .CloseAction {
	margin: 0  0;
	padding-right: 15px;
    }

    a {
	padding: 15px 0;
	text-decoration: none;
	color: #000000;
	border-bottom: 1px solid rgb(0 0 0 / 50%);
	font-weight: 400;
	font-size: 25px;
	width: 100%;
	display: block;
	text-align: center;
    }

    .MobileMenuLink {
	width: 100%;
    }
}
