.VideoWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.VideoWrapper iframe,
.VideoWrapper object,
.VideoWrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
