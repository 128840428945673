@import "../../variables.scss";

.Modal {

    .CloseAction {

	position: absolute;
	top: 0;
	right: 0;
    }
}

.OnTop {
    z-index: $modal-on-top-z-index !important;
}
