@import "../../variables.scss";

.EventSeparator {
    margin: 1px;
    padding: 15px;
    position: relative;
    background-color: #ffffff;
    height: 100%;
    max-height: calc(100% - 2px);
    max-width: calc(100% - 2px);
    background-size: cover;
    background-position: 50%;

    p {
	color: #fff;
	margin: 5px 0 0 0;
	font-weight: 700;
	font-size: 1.17em;
	line-height: 1.2em;
	max-height: 88px;
	overflow: hidden;
	text-overflow: ellipsis;
	letter-spacing: 1px;
    }
}
