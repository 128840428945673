@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {
    margin-top: 25px;
     padding: 0 5px;

    @include gt-xs {
	padding: 0 $padding-desktop;
	margin-top: 100px;
    }

    section {
	max-width: $content-max-width + 50px;

	.Arrow {
	    width: 50px;
	    cursor: pointer;

	    svg {
		font-size: 35px;
	    }

	    .Disabled {
		color: #808080;
		cursor: default;
	    }
	}
    }

    .SeeAgendaWrapper {
	margin-top: 15px;

	@include gt-xs {
	    margin-top: 25px;
	}

	margin-right: 60px;

	.SeeAgenda {
	    width: calc(100% - 50px - 10px);

	    @include gt-xs {
		width: calc((100% - 50px) / 3);
	    }
	}

    }
}

.Carousel {
    width: calc(100% - 100px);
}
