@import "../../mixins.scss";

.Wrapper {
    padding: 10px;

    @inclde gt-xs {
	padding: 40px;
    }

    .Name {
	margin-bottom: 15px;
    }
    
    .Area {
	margin-top: -10px;
	margin-bottom: 15px;
	text-transform: uppercase;
    }

    .Description {
	margin-top: 20px;
    }

    .Recomendations {
	margin-top: 20px;
    }
    
    .Contacts {
	margin-top: 20px;
	a {
	    color: #000000;
	    text-decoration: none;

	    &:hover {
		text-decoration: underline;
	    }
	}
    }
    
    .Cover {
	img {
	    width: 100%;
	}
    }
}

.Metrics {

    margin-top: 20px;
    
    .Name {
	margin-bottom: 5px;
	text-transform: uppercase;
	font-weight: 700;
    }

    .Thumb {
	height: 16px !important;
	width: 16px !important;
	background-color: #f4f4f4;
	border: 2px solid #666666;
	margin-top: -6px !important;
    }
}

.CustomSliderThumb {
  height: 24px;
  width: 24px;
  background-color: #f4f4f4f4;
  border: 2px solid currentColor;
  border-radius: 15px;
}
