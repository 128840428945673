@import "../../variables.scss";
@import "../../mixins.scss";

.Wrapper {

    @include gt-xs {
	max-width: $content-max-width;
	margin: 0 auto;
	margin-top: $header-menus-height + 50px;
	padding: 0 $padding-desktop;
    }

    .Image {
	@include xs {
	    margin-top: $header-menu-height;
	    order: 1;
	    width: 100%;
	    background-size: cover;
	    background-image: url("../../assets/images/institution/award/award_mobile.webp");
	    aspect-ratio: 1.33;
	}

	@include gt-xs {
	    order: 2;
	    background-image: url("../../assets/images/institution/award/award_desktop.webp");
	    background-repeat: no-repeat;
	    background-size: contain;
	}
    }

    .Text {

	@include xs {
	    order: 2;

	    main {
		max-width: $content-max-width;
		padding: $padding-mobile;
	    }
	}

	@include gt-xs {
	    order: 1;

	    main {
		padding: 0 40px 40px 0;
	    }
	}

	section {
	    margin: 20px 0;

	    	    h6 {
		margin: 40px 0;
	    }

	    p {
		margin: 20px 0;
	    }

	    .Winner {
		margin-top: 30px;
		border-top: 1px solid black;
		padding: 10px 0;
		border-bottom: 1px solid black;
		font-size: 18px;

		a {
		    color: #000000;
		    font-weight: bold;
		    text-decoration: none;

		    &:hover {
			text-decoration: underline;
		    }
		}

	    }

	}
    }
}
