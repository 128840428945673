.Circus2018 {
    background-image: url('../../../assets/images/circus/2018/circofundoalt.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    .Intro {
	padding: 100px 0;
	background-color: #214997;

	h3 {
	    color: #fff;
	    max-width: 800px;
	    text-align: center;
	    font-weight: 400;
	    font-size: 1.5em;
	    margin: 2rem 0;
	    line-height: 1.4em;
	}
    }
}

.Hero {
    background-size: cover;
    height: 60vh;
    padding-top: 65vh;
    background-position: center;
    background-image: url('../../../assets/images/circus/2018/circo2018.jpg');

    h1 {
	margin-top: 150px;
	font-size: 3.5rem;
	color: #fff;
	text-align: center;
	font-weight: bold;
    }

    h2 {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	font-size: 1.5rem;
	line-height: 1.4em;
	color: #fff;
	text-align: center;
	font-weight: 400;
    }
}
